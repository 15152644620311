<template>
  <ContentWrapper :links="topLinks">
    <ButtonGroup size="large">
      <Button type="primary" @click="submitHandler" :disabled="loading">
        <Badge v-if="changedKeys.length > 0 && !loading" :count="changedKeys.length" />
        保存所有配置
      </Button>
      <Button @click="resetHandler">重置为当前线上配置</Button>
    </ButtonGroup>
    <Form :labelWidth="200" style="margin-top: 10px">
      <Tabs v-model="selectedTab" type="card">
        <TabPane label="发布按钮图标 (≥ 1.0.85)" name="btn_icon">
          <FormItem :label="desc['btn_icon_brand_new_item']">
            <Input
              :placeholder="desc['btn_icon_brand_new_item']"
              v-model="settings['btn_icon_brand_new_item']"
              disabled
            />
            <ImageUploader
              @uploadSucceed="updateImage.call(this, 'btn_icon_brand_new_item', ...arguments)"
              @removedUploaded="updateImage.call(this, 'btn_icon_brand_new_item', ...arguments)"
              :defaultImages="settings['btn_icon_brand_new_item'] ? [settings['btn_icon_brand_new_item']] : []"
            />
          </FormItem>
          <FormItem :label="desc['btn_icon_used_item']">
            <Input :placeholder="desc['btn_icon_used_item']" v-model="settings['btn_icon_used_item']" disabled />
            <ImageUploader
              @uploadSucceed="updateImage.call(this, 'btn_icon_used_item', ...arguments)"
              @removedUploaded="updateImage.call(this, 'btn_icon_used_item', ...arguments)"
              :defaultImages="settings['btn_icon_used_item'] ? [settings['btn_icon_used_item']] : []"
            />
          </FormItem>
          <FormItem :label="desc['btn_icon_free_item']">
            <Input :placeholder="desc['btn_icon_free_item']" v-model="settings['btn_icon_free_item']" disabled />
            <ImageUploader
              @uploadSucceed="updateImage.call(this, 'btn_icon_free_item', ...arguments)"
              @removedUploaded="updateImage.call(this, 'btn_icon_free_item', ...arguments)"
              :defaultImages="settings['btn_icon_free_item'] ? [settings['btn_icon_free_item']] : []"
            />
          </FormItem>
          <FormItem :label="desc['btn_icon_virtual_item']">
            <Input :placeholder="desc['btn_icon_virtual_item']" v-model="settings['btn_icon_virtual_item']" disabled />
            <ImageUploader
              @uploadSucceed="updateImage.call(this, 'btn_icon_virtual_item', ...arguments)"
              @removedUploaded="updateImage.call(this, 'btn_icon_virtual_item', ...arguments)"
              :defaultImages="settings['btn_icon_virtual_item'] ? [settings['btn_icon_virtual_item']] : []"
            />
          </FormItem>
          <FormItem :label="desc['btn_icon_store_food']">
            <Input :placeholder="desc['btn_icon_store_food']" v-model="settings['btn_icon_store_food']" disabled />
            <ImageUploader
              @uploadSucceed="updateImage.call(this, 'btn_icon_store_food', ...arguments)"
              @removedUploaded="updateImage.call(this, 'btn_icon_store_food', ...arguments)"
              :defaultImages="settings['btn_icon_store_food'] ? [settings['btn_icon_store_food']] : []"
            />
          </FormItem>
        </TabPane>
        <TabPane label="发布按钮标题 (≥ 1.0.85)" name="btn_title">
          <FormItem :label="desc['btn_title_brand_new_item']">
            <Input :placeholder="desc['btn_title_brand_new_item']" v-model="settings['btn_title_brand_new_item']" />
          </FormItem>
          <FormItem :label="desc['btn_title_used_item']">
            <Input :placeholder="desc['btn_title_used_item']" v-model="settings['btn_title_used_item']" />
          </FormItem>
          <FormItem :label="desc['btn_title_free_item']">
            <Input :placeholder="desc['btn_title_free_item']" v-model="settings['btn_title_free_item']" />
          </FormItem>
          <FormItem :label="desc['btn_title_virtual_item']">
            <Input :placeholder="desc['btn_title_virtual_item']" v-model="settings['btn_title_virtual_item']" />
          </FormItem>
          <FormItem :label="desc['btn_title_store_food']">
            <Input :placeholder="desc['btn_title_store_food']" v-model="settings['btn_title_store_food']" />
          </FormItem>
        </TabPane>
        <TabPane label="发布按钮副标题 (≥ 1.0.85)" name="btn_comment">
          <FormItem :label="desc['btn_comment_brand_new_item']">
            <Input :placeholder="desc['btn_comment_brand_new_item']" v-model="settings['btn_comment_brand_new_item']" />
          </FormItem>
          <FormItem :label="desc['btn_comment_used_item']">
            <Input :placeholder="desc['btn_comment_used_item']" v-model="settings['btn_comment_used_item']" />
          </FormItem>
          <FormItem :label="desc['btn_comment_free_item']">
            <Input :placeholder="desc['btn_comment_free_item']" v-model="settings['btn_comment_free_item']" />
          </FormItem>
          <FormItem :label="desc['btn_comment_virtual_item']">
            <Input :placeholder="desc['btn_comment_virtual_item']" v-model="settings['btn_comment_virtual_item']" />
          </FormItem>
          <FormItem :label="desc['btn_comment_store_food']">
            <Input :placeholder="desc['btn_comment_store_food']" v-model="settings['btn_comment_store_food']" />
          </FormItem>
        </TabPane>
        <TabPane label="类型色标签 (≥ 1.0.81)" name="label_bg_color">
          <FormItem :label="desc['label_bg_color_brand_new_item']">
            <Input
              :placeholder="desc['label_bg_color_brand_new_item']"
              v-model="settings['label_bg_color_brand_new_item']"
            />
            <ColorPicker v-model="colors['label_bg_color_brand_new_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['label_bg_color_brand_new_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['label_bg_color_brand_new_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['label_bg_color_used_item']">
            <Input :placeholder="desc['label_bg_color_used_item']" v-model="settings['label_bg_color_used_item']" />
            <ColorPicker v-model="colors['label_bg_color_used_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['label_bg_color_used_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['label_bg_color_used_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['label_bg_color_free_item']">
            <Input :placeholder="desc['label_bg_color_free_item']" v-model="settings['label_bg_color_free_item']" />
            <ColorPicker v-model="colors['label_bg_color_free_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['label_bg_color_free_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['label_bg_color_free_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['label_bg_color_virtual_item']">
            <Input
              :placeholder="desc['label_bg_color_virtual_item']"
              v-model="settings['label_bg_color_virtual_item']"
            />
            <ColorPicker v-model="colors['label_bg_color_virtual_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['label_bg_color_virtual_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['label_bg_color_virtual_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['label_bg_color_store_food']">
            <Input
              :placeholder="desc['label_bg_color_store_food']"
              v-model="settings['label_bg_color_store_food']"
            />
            <ColorPicker v-model="colors['label_bg_color_store_food']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['label_bg_color_store_food'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['label_bg_color_store_food'] }">深色背景效果</span>
            </p>
          </FormItem>
        </TabPane>
        <TabPane label="发布按钮色彩（≤ 1.0.81）" name="btn_bg_color">
          <FormItem :label="desc['btn_bg_color_brand_new_item']">
            <Input
              :placeholder="desc['btn_bg_color_brand_new_item']"
              v-model="settings['btn_bg_color_brand_new_item']"
            />
            <ColorPicker v-model="colors['btn_bg_color_brand_new_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['btn_bg_color_brand_new_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['btn_bg_color_brand_new_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['btn_bg_color_used_item']">
            <Input :placeholder="desc['btn_bg_color_used_item']" v-model="settings['btn_bg_color_used_item']" />
            <ColorPicker v-model="colors['btn_bg_color_used_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['btn_bg_color_used_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['btn_bg_color_used_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['btn_bg_color_free_item']">
            <Input :placeholder="desc['btn_bg_color_free_item']" v-model="settings['btn_bg_color_free_item']" />
            <ColorPicker v-model="colors['btn_bg_color_free_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['btn_bg_color_free_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['btn_bg_color_free_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['btn_bg_color_virtual_item']">
            <Input :placeholder="desc['btn_bg_color_virtual_item']" v-model="settings['btn_bg_color_virtual_item']" />
            <ColorPicker v-model="colors['btn_bg_color_virtual_item']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['btn_bg_color_virtual_item'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['btn_bg_color_virtual_item'] }">深色背景效果</span>
            </p>
          </FormItem>
          <FormItem :label="desc['btn_bg_color_store_food']">
            <Input :placeholder="desc['btn_bg_color_store_food']" v-model="settings['btn_bg_color_store_food']" />
            <ColorPicker v-model="colors['btn_bg_color_store_food']" alpha editable @on-change="colorChange" />
            <p>预览</p>
            <p class="preview white">
              <span :style="{ backgroundColor: colors['btn_bg_color_store_food'] }">白色背景效果</span>
            </p>
            <p class="preview black">
              <span :style="{ backgroundColor: colors['btn_bg_color_store_food'] }">深色背景效果</span>
            </p>
          </FormItem>
        </TabPane>
      </Tabs>
    </Form>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import ImageUploader from "@/components/ImageUploader.vue"
import { queryAllSettings, updateAllSettings } from "@/api"
import { apiUtil, hexToRgba, rgbaToHex } from "@/util"

const settingKeys = [
  "btn_bg_color_brand_new_item",
  "btn_bg_color_used_item",
  "btn_bg_color_free_item",
  "btn_bg_color_virtual_item",
  "btn_bg_color_store_food",
  "label_bg_color_brand_new_item",
  "label_bg_color_used_item",
  "label_bg_color_free_item",
  "label_bg_color_virtual_item",
  "label_bg_color_store_food",
  "btn_icon_brand_new_item",
  "btn_icon_used_item",
  "btn_icon_free_item",
  "btn_icon_virtual_item",
  "btn_icon_store_food",
  "btn_title_brand_new_item",
  "btn_title_used_item",
  "btn_title_free_item",
  "btn_title_virtual_item",
  "btn_title_store_food",
  "btn_comment_brand_new_item",
  "btn_comment_used_item",
  "btn_comment_free_item",
  "btn_comment_virtual_item",
  "btn_comment_store_food",
]
const colorKeys = [
  "btn_bg_color_brand_new_item",
  "btn_bg_color_used_item",
  "btn_bg_color_free_item",
  "btn_bg_color_virtual_item",
  "btn_bg_color_store_food",
  "label_bg_color_brand_new_item",
  "label_bg_color_used_item",
  "label_bg_color_free_item",
  "label_bg_color_virtual_item",
  "label_bg_color_store_food",
]

const initDescs = () => {
  const obj = {}
  settingKeys.forEach(k => {
    obj[k] = "加载中"
  })
  return obj
}

const initSettings = () => {
  const obj = {}
  settingKeys.forEach(k => {
    obj[k] = ""
  })
  return obj
}

const initColors = () => {
  const obj = {}
  colorKeys.forEach(k => {
    obj[k] = "rgba(255, 255, 255, 255)"
  })
  return obj
}

export default {
  name: "UISettingsPage",
  components: { ContentWrapper, ImageUploader },
  data() {
    return {
      changedKeys: [],
      topLinks: [{ title: "界面配置", link: this.$route.path }],
      desc: Object.assign({}, initDescs()),
      settings: Object.assign({}, initSettings()),
      onlineSettings: Object.assign({}, initSettings()),
      colors: Object.assign({}, initColors()),
      loading: true,
      selectedTab: "btn_icon",
    }
  },
  computed: {},
  watch: {
    settings: {
      deep: true,
      handler(newV) {
        this.changedKeys = []
        Object.keys(newV).forEach(k => {
          if (newV[k] != this.onlineSettings[k]) {
            this.changedKeys.push(k)
          }
          if (k.indexOf("color") > -1) {
            const v = newV[k]
            this.colors[k] = hexToRgba(v)
          }
        })
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        this.loading = true
        const fetchedSettings = await queryAllSettings()
        Object.keys(fetchedSettings).forEach(k => {
          this.desc[k] = fetchedSettings[k][0]
          this.settings[k] = fetchedSettings[k][1]
          this.onlineSettings[k] = fetchedSettings[k][1]
        })
        this.changedKeys = []
        this.loading = false
      })
    },
    async submitHandler() {
      this.$Modal.confirm({
        title: "确认",
        content: "这些改动将会影响到线上应用的显示，请确认",
        onOk: () => {
          apiUtil.call(this, async () => {
            this.loading = true
            await updateAllSettings(Object.assign({}, this.settings))
            await this.reload()
          })
        },
      })
    },
    resetHandler() {
      this.settings = Object.assign({}, this.settings, initSettings())
      this.reload()
    },
    colorChange() {
      Object.keys(this.colors).forEach(k => {
        const v = this.colors[k]
        this.settings[k] = rgbaToHex(v)
      })
    },
    updateImage(key, images) {
      if (images && images.length == 1) {
        this.settings[key] = images[0].path
      } else {
        this.settings[key] = ""
      }
    },
  },
}
</script>

<style scope>
.app-settings-key {
  color: #808695;
}

p.preview {
  width: 200px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
}

p.preview > span {
  display: block;
  width: 120px;
  height: 40px;
  margin: 30px auto 0;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: default;
}

p.white {
  background: #fff;
}

p.black {
  background: #333;
}
</style>
